<template>
	<div class="my-alert">
		<div class="text-center">
			<v-snackbar v-model="Snackbar" :timeout="timeout" top right>
				<v-alert
					v-if="$store.state.auth.alert.type === 'success'"
					dense
					text
					min-height="48"
					width="inherit"
					type="success"
					align-center
				>
					<div class="d-flex flex-row">
						<span class="mr-4">{{ this.$store.state.auth.alert.message }}</span>
						<div class="d-flex ml-auto justify-center align-center success-close">
							<img :src="$store.state.icons.icons['RedCross']" v-on:click="resetAlert()" />
						</div>
					</div>
				</v-alert>

				<v-alert
					v-else-if="this.$store.state.auth.alert.type === 'error'"
					dense
					outlined
					min-height="50"
					width="inherit"
					type="error"
				>
					<div class="d-flex flex-row">
						<span class="mr-4">{{ this.$store.state.auth.alert.message }}</span>
						<div class="d-flex ml-auto justify-center align-center error-close">
							<img :src="$store.state.icons.icons['RedCross']" v-on:click="resetAlert()" />
						</div>
					</div>
				</v-alert>
			</v-snackbar>
		</div>
	</div>
</template>

<script>
export default {
	data: () => ({
		snackbar: "",
		text: "My timeout is set to 6000.",
		timeout: 6000
	}),

	watch: {
		isActive(val) {
			console.log("hello");
		}
	},

	computed: {
		Snackbar: {
			get() {
				this.snackbar = this.$store.state.auth.alert.show;
				return this.snackbar;
			},
			set(setvalue) {
				this.resetAlert();
				this.$emit("input", setvalue);
			}
		}
	},

	methods: {
		resetAlert() {
			this.snackbar = false;
			let alert = {
				show: false,
				message: "",
				type: ""
			};
			this.$store.commit("showAlert", alert);
		}
	}
};
</script>

<style lang="scss">
.my-alert {
	.success-close {
		height: 22px;
		width: 22px;
		border-radius: 50% !important;
		background: #4caf4f !important;

		img {
			height: 12px !important;
			cursor: pointer;
			filter: brightness(0) saturate(100%) invert(96%) sepia(96%)
				saturate(0%) hue-rotate(90deg) brightness(105%) contrast(103%);
		}
	}

	.error-close {
		height: 22px;
		width: 22px;
		border-radius: 50% !important;
		background: #fd5252 !important;

		img {
			height: 12px !important;
			cursor: pointer;
			filter: brightness(0) saturate(100%) invert(96%) sepia(96%)
				saturate(0%) hue-rotate(90deg) brightness(105%) contrast(103%);
		}
	}

	.v-snack__content {
		padding: 0px !important;
	}

	.v-snack__wrapper {
		background-color: #ffffff !important;
		height: auto !important;
		width: auto !important;
	}

	.v-alert {
		margin: 0px !important;
		min-width: inherit !important;
		padding: 12px 16px !important;
	}
}
.v-alert{
	z-index: 1
}
</style>